import { Box } from "components/ui/box";
import React from "react";
import { ResponsiveText } from "./shared";
import { useTranslation } from "components/translate";
import { InlineSpan } from "components/as-inline-span";
import { TestmonialList } from "../testmonial-list";
import TypeWriterWrapper from "components/type-writer";

const Testmonial = ({ title, titleProps = {} }) => {
  const { t } = useTranslation("front-page");
  return (
    <Box component="section">
      <Box
        sx={{
          marginBottom: "48px",
        }}
      >
        <ResponsiveText
          fontSize={45}
          variant="h2"
          sx={{
            textAlign: "center",
            margin: "auto",
            color: "text.white",
            ...(titleProps.sx || {}),
          }}
        >
          {title ? (
            title
          ) : (
            <>
              <InlineSpan inherit>{t("An AI Writer Loved by")} </InlineSpan>
              <InlineSpan
                inherit
                sx={{
                  color: "#E910D3",
                }}
              >
                <TypeWriterWrapper
                  words={[
                    "Copywriters",
                    "Entrepreneurs",
                    "Marketers",
                    "Bloggers",
                    "Startups",
                  ]}
                  typeSpeed={0}
                  deleteSpeed={0}
                  delaySpeed={2000}
                  cursor={false}
                />
              </InlineSpan>
            </>
          )}
        </ResponsiveText>
        <ResponsiveText
          fontSize={18}
          sx={{
            color: "#D4DCE5",
            maxWidth: "500px",
            textAlign: "center",
            padding: "0 12px",
            margin: "12px auto auto auto",
          }}
        >
          {t(
            "Don't just take our word for it. See what people find amazing about us."
          )}
        </ResponsiveText>
      </Box>
      <TestmonialList />
    </Box>
  );
};

export default Testmonial;
