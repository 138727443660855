import React from "react";

import { type EnrichedTweet, formatNumber } from "../utils";
import { TweetActionsCopy } from "./tweet-actions-copy";
import s from "./tweet-actions.module.css";

export const TweetActions = ({ tweet }: { tweet: EnrichedTweet }) => {
  const favoriteCount = formatNumber(tweet.favorite_count);

  return (
    <div className={s.actions}>
      <a
        className={s.like}
        href={tweet.like_url}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={`Like. This Tweet has ${favoriteCount} likes`}
      >
        <div className={s.likeIconWrapper}>
          <svg viewBox="0 0 24 24" className={s.likeIcon} aria-hidden="true">
            <g>
              <path d="M4 4.5C4 3.12 5.119 2 6.5 2h11C18.881 2 20 3.12 20 4.5v18.44l-8-5.71-8 5.71V4.5zM6.5 4c-.276 0-.5.22-.5.5v14.56l6-4.29 6 4.29V4.5c0-.28-.224-.5-.5-.5h-11z"></path>
            </g>
          </svg>
        </div>
        <span className={s.likeCount}>{tweet.bookmarks}</span>
      </a>
      <a
        className={s.reply}
        href={tweet.reply_url}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Reply to this Tweet on Twitter"
      >
        <div className={s.replyIconWrapper}>
          <svg viewBox="0 0 24 24" className={s.replyIcon} aria-hidden="true">
            <g>
              <path d="M8.75 21V3h2v18h-2zM18 21V8.5h2V21h-2zM4 21l.004-10h2L6 21H4zm9.248 0v-7h2v7h-2z"></path>
            </g>
          </svg>
        </div>
        <span className={s.replyText}>{tweet.views}</span>
      </a>
      <TweetActionsCopy tweet={tweet} />
    </div>
  );
};
