import React from "react";
import { Box } from "components/ui/box";
import { Typography } from "components/ui/Typography";
import Balancer from "react-wrap-balancer";
import { headerHeight } from "modules/non-protected-page/shared";
import {
  Container,
  ResponsiveText,
  headlineSizeConfig,
} from "modules/marketing-pages/front-page/shared";
import HowItWorks from "modules/marketing-pages/front-page/how-it-works";
import ReadyToLevelUp from "modules/marketing-pages/ready-to-level-up";
import "react-multi-carousel/lib/styles.css";
import { Grid } from "components/ui/grid";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import {
  dashboardRouteConfig,
  serverRoutes,
} from "client-server-shared/config/routes";
import MoreFeatureList from "modules/marketing-pages/front-page/more-feature-list";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { isCn, locale } from "client-server-shared/constants";
import { Trans, useTranslation } from "components/translate";
import { IndexPageHeadTags } from "common/head-tags";
import { PrimaryCallToAction } from "modules/marketing-pages/call-to-action";
import Image from "next/image";
import DescriptionIcon from "@mui/icons-material/Description";
import AIFace1 from "public/materials/ai-face-1.png";
import AIFace2 from "public/materials/ai-face-2.png";
import AIFace3 from "public/materials/ai-face-3.png";
import JuniaBlog from "public/materials/junia-blog.png";
import JuniaBlogMobile from "public/materials/junia-blog-mobile.png";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ContentTypes from "public/materials/content-types.png";
import { BlackButton, Button } from "components/ui/button";
import { Avatar } from "components/ui/avatar";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import StorefrontIcon from "@mui/icons-material/Storefront";
import DraftsIcon from "@mui/icons-material/Drafts";
import LocalSeeIcon from "@mui/icons-material/LocalSee";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import { FeatureItem } from "modules/non-protected-page/feature-list";
import JuniaChat from "public/materials/junia-chat.png";
import { NextLink } from "components/next-link";
import { templateServerRouteBySlug } from "client-server-shared/config/routes";
import JuniaArtSection from "modules/marketing-pages/front-page/junia-art-section";
import Testmonial from "modules/marketing-pages/front-page/testmonial";
import Amazing from "modules/marketing-pages/front-page/amazing";
import Script from "next/script";
import MicOutlinedIcon from "@mui/icons-material/MicOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { HighlightedSpan, InlineSpan } from "components/as-inline-span";
import { AutoPlayVideo } from "components/video/auto-play-video";
import { Features } from "modules/marketing-pages/front-page/features";
import Faq, {
  useFaqList,
} from "modules/marketing-pages/front-page/front-page-faq";
import { buildJsonLd } from "modules/marketing-pages/faq-to-json-ld";
import { EmbeddedTweet } from "external/react-tweet";
import { getTweet } from "external/react-tweet/api";
import { ReviewMarkup } from "modules/marketing-pages/testmonial-list";
import { WriterList } from "modules/auth/image-list";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
const AboutJunia = () => {
  const { t } = useTranslation("front-page");
  return (
    <Container
      maxWidth="md"
      component="section"
      sx={{
        paddingTop: "48px",
        paddingBottom: "48px",
      }}
    >
      <Testmonial />
    </Container>
  );
};

const HumanCard = ({
  image,
  alt,
  text,
  extraText,
}: {
  image: string;
  text: string;
  alt: string;
}) => {
  return (
    <Box
      component="figure"
      sx={{
        display: "flex",
        flexDirection: "column",
        rowGap: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Image src={image} alt={alt} height={60} width={60} />
      </Box>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          padding: "16px 24px",
          borderRadius: "15px",
          position: "relative",
          opacity: 0.8,
          marginRight: "24px",
          "&:before": {
            content: "''",
            position: "absolute",
            width: 0,
            height: 0,
            borderWidth: "10px",
            top: "5px",
            right: "-5px",
            border: "21px solid transparent",
            borderBottomColor: "#FFFFFF",
            borderRight: "0px solid",
            borderTop: 0,
            marginTop: "-17px",
            transform: "rotate(24deg)",
          },
        }}
      >
        <Typography color="#000000" component="figcaption">
          {text}
        </Typography>
      </Box>
      {extraText && (
        <Box
          sx={{
            position: "relative",
            marginRight: "24px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Box
            sx={{
              padding: "16px 24px",
              position: "relative",
              display: "flex",
              borderRadius: "15px",
              backgroundColor: "#FFFFFF",
              opacity: 0.8,

              alignItems: "center",
              justifyContent: "space-between",
              "&:before": {
                content: "''",
                position: "absolute",
                width: 0,
                height: 0,
                borderWidth: "10px",
                top: "5px",
                right: "-5px",

                border: "21px solid transparent",
                borderBottomColor: "#FFFFFF",
                borderRight: "0px solid",
                borderTop: 0,
                marginTop: "-17px",
                transform: "rotate(24deg)",
              },
            }}
          >
            <Typography color="#000000" component="figcaption">
              {extraText}
            </Typography>
            <DescriptionIcon
              sx={{
                marginLeft: "12px",
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

const HumanCardLists = () => {
  const { t } = useTranslation("front-page");
  return (
    <Box
      sx={{
        position: "absolute",
        right: 0,
        top: "0",
        display: {
          xs: "none",
          sm: "none",
          md: "none",
          lg: "initial",
        },
      }}
    >
      <AutoPlayVideo
        style={{
          borderRadius: "22px",
        }}
        controls={false}
        videoUrl="/chat1.mp4"
      />
    </Box>
  );
  return (
    <Box
      sx={{
        position: "absolute",
        right: 0,
        top: "30%",
        display: {
          xs: "none",
          sm: "none",
          md: "none",
          lg: "initial",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <HumanCard
          position="right"
          image={AIFace2}
          alt={t("A happy marketer who uses Junia AI for file analysis")}
          text={t("Summarize this file")}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          marginRight: "80px",
          marginTop: "24px",
        }}
      >
        <Box
          component="figure"
          sx={{
            display: "flex",
            rowGap: "24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "-35px",
            }}
          >
            <Image
              alt={t(
                "A happy writer who uses Junia AI to write recurring newsletter"
              )}
              src={AIFace1}
              height={60}
              width={60}
            />
          </Box>
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              padding: "16px 24px",
              opacity: 0.8,
              borderRadius: "15px",
              position: "relative",
              marginLeft: "24px",
              "&:before": {
                content: "''",
                position: "absolute",
                width: 0,
                height: 0,
                borderWidth: "10px",
                top: "9px",
                left: "-18px",

                border: "21px solid transparent",
                borderTopColor: "#FFFFFF",
                borderRight: "0px solid",
                marginTop: "-18px",
                transform: "rotate(42deg)",
              },
            }}
          >
            <Typography color="#000000" component="figcaption">
              {t("Write a product description for our product")}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "-55px",
        }}
      >
        <HumanCard
          position="right"
          image={AIFace3}
          alt={t(
            "A happy blogger who uses Junia AI to create images for his blog"
          )}
          text={t("Create an image for my blog post")}
        />
      </Box>
    </Box>
  );
};

const PurpleLinePointer1 = ({ sx = {} }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      viewBox="0 0 1516 1887"
      fill="none"
      style={sx}
    >
      <path
        d="M1515.5 17.0045L0 17.0053"
        stroke="#BF32D6"
        strokeWidth="32.587"
      />
      <line
        x1="548.706"
        y1="1816.12"
        x2="548.706"
        y2="23.1172"
        stroke="#BF32D6"
        strokeWidth="32.587"
      />
      <circle cx="549" cy="1832" r="55" fill="#BF32D6" />
    </svg>
  );
};

const SecondSection1 = () => {
  const { t } = useTranslation("front-page");
  return (
    <Box
      sx={{
        position: "absolute",
        top: "-180px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "6px",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
          }}
          fontWeight={700}
          component="strong"
          variant="body1"
          color="text.white"
        >
          {t("AI writer")}
        </Typography>
        <Typography
          sx={{
            lineHeight: 1.3,
            maxWidth: "250px",
          }}
          color="#BCBCBC"
        >
          {t("Generate comprehensive people-first content in minutes")}
        </Typography>
        <PurpleLinePointer1
          sx={{
            zIndex: 2,
          }}
        />
      </Box>
    </Box>
  );
};

const SecondSection2 = () => {
  const { t } = useTranslation("front-page");

  return (
    <Box
      sx={{
        position: "absolute",
        top: "-100px",
        left: "30%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "6px",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
          }}
          fontWeight={700}
          component="strong"
          variant="body1"
          color="text.white"
        >
          {t("AI images")}
        </Typography>
        <Typography
          sx={{
            lineHeight: 1.3,
            maxWidth: "250px",
          }}
          color="#BCBCBC"
        >
          {t("Enhance your content with AI-generated images")}
        </Typography>
        <PurpleLinePointer1
          sx={{
            zIndex: 1,
          }}
        />
      </Box>
    </Box>
  );
};

const SecondSection3 = () => {
  const { t } = useTranslation("front-page");

  return (
    <Box
      sx={{
        position: "absolute",
        top: "-170px",
        right: "0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "6px",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
          }}
          fontWeight={700}
          component="strong"
          variant="body1"
          color="text.white"
        >
          {t("SEO Research")}
        </Typography>
        <Typography
          sx={{
            lineHeight: 1.3,
            maxWidth: "250px",
          }}
          color="#BCBCBC"
        >
          {t("Create outlines and optimize your content for search engines")}
        </Typography>
        <PurpleLinePointer1
          sx={{
            zIndex: 1,
          }}
        />
      </Box>
    </Box>
  );
};

const SecondSection4 = () => {
  const { t } = useTranslation("front-page");

  return (
    <Box
      sx={{
        background: "rgba(255, 255, 255, 0.8)",
        position: "absolute",
        padding: "24px",
        borderRadius: "15px",
        top: "50%",
        zIndex: 3,
        left: "-100px",
        boxShadow: "inset 10px -50px 94px 0 hsla(0,0%,78%,.4)",
      }}
    >
      <Typography
        sx={{
          maxWidth: "200px",
          textAlign: "center",
        }}
      >
        <Typography
          fontWeight={800}
          component="strong"
          sx={{
            color: "#9E07B6",
            fontSize: "18px",
          }}
        >
          {t("Write 3000+ word long form content")}
        </Typography>
      </Typography>
    </Box>
  );
};

const SecondSection = () => {
  const { t } = useTranslation("front-page");

  return (
    <Box
      component="section"
      sx={{
        padding: "70px 0",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "flex",
            },
          }}
        >
          <Box
            sx={{
              position: "relative",
              zIndex: 2,
              width: "100%",
              "& > img": {
                height: "100%",
                width: "100%",
              },
            }}
          >
            <Image
              alt={t(
                "Interface of Junia AI's Rich Text Editor for creating seo optimized long form content with images generated by AI"
              )}
              src={JuniaBlog}
              placeholder="blur"
              height={700}
              width={1000}
            />
          </Box>
          <SecondSection1 />
          <SecondSection2 />
          <SecondSection3 />
          <SecondSection4 />
        </Box>
        <Box
          component={Image}
          src={JuniaBlogMobile}
          placeholder="blur"
          height={700}
          width={700}
          alt={t(
            "Interface of Junia AI's Rich Text Editor for creating seo optimized long form content with images generated by AI"
          )}
          sx={{
            width: "100%",
            height: "100%",
            display: {
              xs: "flex",
              sm: "flex",
              md: "flex",
              lg: "none",
            },
          }}
        />
      </Container>
    </Box>
  );
};

const OverlapIcon = ({ icon, jsxIcon, noIconBg, sx = {}, reverse, index }) => {
  const Icon = icon;

  if (reverse) {
    return (
      <Box
        sx={{
          position: "relative",
          ...sx,
        }}
      >
        <Avatar
          sx={{
            width: "45px",
            height: "45px",

            background: "#CF24BE",
            ...(noIconBg ? { background: "transparent" } : {}),
          }}
        >
          <Box />
        </Avatar>
        <Avatar
          sx={{
            width: "45px",
            height: "45px",
            background: "transparent",
            bottom: "-15px",
            left: "-12px",
            position: "absolute",
            "&:after": {
              content: "''",
              background: "#F5F5F5",

              opacity: 0.4,
              position: "absolute",
              width: "100%",
              height: "100%",
            },
          }}
        >
          <Icon
            sx={{
              zIndex: 2,
              width: "17px",
              height: "17px",
              fill: "white",
            }}
          />
        </Avatar>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: "relative",
        ...sx,
      }}
    >
      <Avatar
        sx={{
          width: "45px",
          height: "45px",
          position: "relative",
          background: "#CF24BE",
          ...(noIconBg ? { background: "transparent" } : {}),
        }}
      >
        {jsxIcon || index}
      </Avatar>
    </Box>
  );
};

const ThirdSectionSection = ({
  jsxIcon,
  icon,
  title,
  description,
  isLast,
  index,
  noIconBg,
  titleProps = {},
}) => {
  return (
    <Box
      component="li"
      sx={{
        display: "flex",
        columnGap: "48px",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <OverlapIcon
          noIconBg={noIconBg}
          jsxIcon={jsxIcon}
          icon={icon}
          index={index}
        />
        <Box
          sx={{
            height: "50px",
            width: "1px",
            borderLeft: isLast ? 0 : "1px dashed #b59fd0",
            marginTop: "20px",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "12px",
          marginTop: "-6px",
        }}
      >
        <ResponsiveText
          color="text.white"
          fontSize={24}
          decrement="medium"
          {...titleProps}
          sx={{
            fontWeight: 700,
            ...(titleProps.sx || {}),
          }}
        >
          {title}
        </ResponsiveText>
        <ResponsiveText
          fontSize={18}
          decrement="medium"
          sx={{
            maxWidth: "400px",
          }}
          fontWeight={500}
          color="#BCBCBC"
        >
          {description}
        </ResponsiveText>
      </Box>
    </Box>
  );
};

const ThirdSection = () => {
  const { t } = useTranslation("front-page");
  return (
    <Box
      component="section"
      sx={{
        padding: {
          xs: "70px 0",
          sm: "70px 0",
          md: "70px 0",
          lg: "124px 0",
        },
      }}
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            alignItems: {
              xs: "center",
              sm: "center",
              md: "center",
              lg: "flex-start",
            },
            padding: "0 12px",
            justifyContent: "space-between",
            flexDirection: {
              xs: "column-reverse",
              sm: "column-reverse",
              md: "column-reverse",
              lg: "row-reverse",
            },
          }}
        >
          <Box
            component="ul"
            sx={{
              padding: 0,
              margin: 0,
              display: "flex",
              flexDirection: "column",
              rowGap: "36px",
            }}
          >
            <ThirdSectionSection
              icon={CreateOutlinedIcon}
              index={1}
              title={t("One-Click Blog Image Generation")}
              description={t(
                "Just one click and our AI handles image selection, optimization, and placement for your blog content."
              )}
            />
            <ThirdSectionSection
              index={2}
              icon={AttachMoneyOutlinedIcon}
              title={t("End The Struggle For Visual Appeal")}
              description={t(
                "Compensate for lack of eye-catching visuals with top-tier, AI-selected images that make your blog posts stand out."
              )}
            />
            <ThirdSectionSection
              index={3}
              icon={QueryBuilderOutlinedIcon}
              title={t("Boost Engagement with Your Content")}
              isLast
              description={t(
                "Increase reader engagement, attract quality traffic consistently with visually appealing blog posts that enhance SEO ranking."
              )}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "12px",
              marginBottom: {
                xs: "70px",
                sm: "70px",
                md: "70px",
                lg: "0",
              },
            }}
          >
            <ResponsiveText
              color="text.white"
              variant="h2"
              fontSize={55}
              decrement="large"
              sx={{
                maxWidth: "500px",
              }}
            >
              {t("Boost Your Rankings with AI-Generated Images")}
            </ResponsiveText>
            <ResponsiveText
              fontSize={20}
              decrement="medium"
              sx={{
                maxWidth: "400px",
              }}
              color="#BCBCBC"
            >
              Elevate your website traffic starting this week by enhancing your
              blog posts with high-quality images, selected and optimized by our
              advanced AI.
            </ResponsiveText>

            <Box>
              <Button
                capitalize
                variant="contained"
                component={NextLink}
                href={serverRoutes.register}
                sx={{
                  marginTop: "12px",
                  borderRadius: "22px",
                  padding: "12px 36px",
                  background: "#CF24BE",
                  fontSize: "20px",
                  "&:hover": {
                    background: "#CF24BE",
                    opacity: 0.8,
                  },
                }}
              >
                <ResponsiveText fontSize={20} decrement="medium">
                  {t("Enhance Blog Visuals")}
                </ResponsiveText>
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

const FourthSectionSection = ({ icon, to, title, description }) => {
  const Icon = icon;

  return (
    <Box
      component="li"
      sx={{
        width: "100%",
      }}
    >
      <Button
        component={NextLink}
        capitalize
        target="__blank"
        href={to}
        variant="contained"
        sx={{
          display: "flex",
          textDecoration: "none",
          width: {
            xs: "100%",
            sm: "100%",
            md: "500px",
            lg: "500px",
          },
          justifyContent: "space-between",
          alignItems: "center",
          background: "white",
          borderRadius: "15px",
          padding: "24px",
          margin: "auto",
          "&:hover": {
            background: "white",
            opacity: 0.8,
          },
          "&:focus": {
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "24px",
          }}
        >
          <Avatar
            sx={{
              background: "rgba(54, 15, 103, 0.25)",
            }}
          >
            <Icon
              sx={{
                fill: "#360F67",
              }}
            />
          </Avatar>
          <Box>
            <Typography variant="body2" color="#747474">
              {title}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
              }}
              fontWeight={600}
              variant="body1"
              color="#000000"
            >
              {description}
            </Typography>
          </Box>
        </Box>
        <ChevronRightOutlinedIcon
          sx={{
            fill: "initial",
          }}
        />
      </Button>
    </Box>
  );
};

const FithSection = () => {
  const { t } = useTranslation("front-page");

  return (
    <Box
      component="section"
      sx={{
        padding: "124px 0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "24px",
            padding: {
              xs: "0 24px",
              sm: "0 24px",
              md: "0 0 0 20%",
              lg: "0 0 0 20%",
            },
          }}
        >
          <Box
            sx={{
              position: "relative",
            }}
          >
            <ResponsiveText
              sx={{
                zIndex: 1,
                position: "relative",
                maxWidth: "600px",
              }}
              fontSize={50}
              decrement="medium"
              color="text.white"
              variant="h3"
            >
              {t("More than 50+ AI Templates to choose from")}
            </ResponsiveText>
            <Box
              sx={{
                position: "absolute",
                background:
                  "linear-gradient(180deg, rgba(24, 75, 255, 0) 0%, rgba(23, 74, 255, 0.6) 100%)",
                height: "200px",
                width: "200px",
                filter: "blur(42px)",
                opacity: 0.6,
                top: 0,
                transform: "rotate(44.76deg)",
              }}
            />
          </Box>
          <ResponsiveText
            sx={{
              zIndex: 1,
              maxWidth: "400px",
            }}
            fontSize={22}
            decrement="medium"
            color="text.white"
          >
            {t(
              "Streamline brainstorming, outlining, drafting, and editing for blogs, emails, ads, and more."
            )}
          </ResponsiveText>
          <Box>
            <Button
              variant="contained"
              capitalize
              component="a"
              target="__blank"
              href={serverRoutes.templates}
              sx={{
                backgroundColor: "#604088",
                color: "white",
                borderRadius: "10px",
                padding: "12px 24px",
                fontSize: "18px",
                "&:hover": {
                  backgroundColor: "#604088",
                  opacity: 0.8,
                },
              }}
              endIcon={<ChevronRightOutlinedIcon />}
            >
              <ResponsiveText fontSize={18} decrement="medium">
                {t("See Templates")}
              </ResponsiveText>
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "initial",
            },
          }}
        >
          <Image
            src={ContentTypes}
            alt={t(
              "Four squares with the words blog post, product description, and resume written on them."
            )}
            height={500}
            width={500}
            style={{
              width: "100%",
              height: "100%",
            }}
          ></Image>
        </Box>
      </Box>
    </Box>
  );
};

const SithSectionSection = ({ text, icon }) => {
  const Icon = icon;

  return (
    <Box
      component="li"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        rowGap: "12px",
      }}
    >
      <Icon
        sx={{
          height: "60px",
          width: "60px",
          fill: "#FFC107",
        }}
      />
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: 600,
        }}
        color={"#5DADE2"}
      >
        {text}
      </Typography>
    </Box>
  );
};

const SithSection = () => {
  const { t } = useTranslation("front-page");
  return (
    <Box
      component="section"
      sx={{
        padding: "0 0 170px 0",
      }}
    >
      <Container maxWidth="lg">
        <ResponsiveText
          variant="h4"
          fontSize={40}
          sx={{
            marginBottom: "72px",
          }}
          textAlign={"center"}
          color="text.white"
          fontWeight={600}
        >
          {t("Junia is used by")}
        </ResponsiveText>

        <Grid
          container
          component="ul"
          sx={{
            listStyle: "none",
            padding: 0,
            margin: 0,
            justifyContent: "space-around",
            rowGap: "24px",
          }}
        >
          <Grid xs={6} md={3} lg={2}>
            <SithSectionSection
              icon={AutoGraphOutlinedIcon}
              text={t("Marketers")}
            />
          </Grid>
          <Grid xs={6} md={3} lg={2}>
            <SithSectionSection
              icon={LightbulbOutlinedIcon}
              text={t("Entrepreneurs")}
            />
          </Grid>
          <Grid xs={6} md={3} lg={2}>
            <SithSectionSection
              icon={SchoolOutlinedIcon}
              text={t("Students")}
            />
          </Grid>
          <Grid xs={6} md={3} lg={2}>
            <SithSectionSection
              icon={ContentPasteOutlinedIcon}
              text={t("Content creators")}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const JuniaChatSectionSection = ({ text, description, icon }) => {
  return (
    <Box component="li">
      <Box
        component="article"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          rowGap: "24px",
        }}
      >
        <OverlapIcon reverse icon={icon || CreateOutlinedIcon} sx={{}} />
        <ResponsiveText
          fontSize={20}
          sx={{
            fontSize: "20px",
            fontWeight: 600,
            color: "#000000",
          }}
        >
          {text}
        </ResponsiveText>
        <ResponsiveText
          fontSize={16}
          sx={{
            maxWidth: "400px",
          }}
          color="#454545"
        >
          {description}
        </ResponsiveText>
      </Box>
    </Box>
  );
};

const JuniaChatSection = () => {
  const { t } = useTranslation("front-page");
  return (
    <Box component="section" sx={{}}>
      <Container maxWidth="lg">
        <FeatureItem
          type="image"
          imageUrl={JuniaChat}
          useCheckMark={false}
          imageBeforeTitleOnMobile
          imageAlt={t("Junia AI Chat User Interface")}
          headlineProps={{
            fontSize: 40,
            sx: {
              color: "initial",
              fontWeight: 600,
            },
          }}
          contentProps={{
            sx: {
              color: "initial",
              fontWeight: 400,
            },
          }}
          headline={t("Meet Junia chat, the AI chatbot that outsmarts chatgpt")}
          content={[
            t("junia-chat-section-1"),
            t("junia-chat-section-2"),
            t("junia-chat-section-3"),
          ]}
          slot={{
            end: (
              <Box>
                <Button
                  capitalize
                  variant="contained"
                  component={NextLink}
                  href={serverRoutes.register}
                  sx={{
                    marginTop: "24px",
                    borderRadius: "22px",
                    padding: "12px 36px",
                    background: "#CF24BE",
                    fontSize: "16px",
                    "&:hover": {
                      background: "#CF24BE",
                      opacity: 0.8,
                    },
                  }}
                >
                  {t("Try For Free!")}
                </Button>
              </Box>
            ),
          }}
        />

        <Box
          component="section"
          sx={{
            padding: "70px 0 124px 0",
          }}
        >
          <ResponsiveText
            variant="h5"
            fontSize={20}
            sx={{
              color: "#BFBFBF",
              marginBottom: "48px",
              textAlign: {
                xs: "center",
                sm: "center",
                md: "center",
                lg: "left",
              },
            }}
          >
            {t("FEATURES OF JUNIA CHAT")}
          </ResponsiveText>

          <Grid
            container
            component="ul"
            columnSpacing={"48px"}
            rowSpacing={"48px"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              margin: 0,
              listStyle: "none",
              padding: 0,
            }}
          >
            <Grid xs={12} sm={7} md={7} lg={4}>
              <JuniaChatSectionSection
                text={t("Real time google search")}
                description={t(
                  "Get instant answers to your questions with real-time Google search feature. No need to leave the chat - just ask and receive accurate information in seconds."
                )}
              />
            </Grid>
            <Grid xs={12} sm={7} md={7} lg={4}>
              <JuniaChatSectionSection
                text={t("Voice command")}
                icon={MicOutlinedIcon}
                description={t(
                  "Tired of typing? Use voice command feature to ask questions using your voice, no typing required! Get answers, insights, and assistance without lifting a finger."
                )}
              />
            </Grid>
            <Grid xs={12} sm={7} md={7} lg={4}>
              <JuniaChatSectionSection
                icon={CloudUploadOutlinedIcon}
                text={t("File and Link Analysis")}
                description={t(
                  "You can easily upload files or links for analysis and receive helpful insights on any topic. Simply upload your file or share a link, and let our AI do the rest! Get accurate information and insights in seconds."
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

const Hero = React.memo(() => {
  const { t } = useTranslation("front-page");
  const mainHero: Record<string, any> = t("main-hero", { returnObjects: true });
  return (
    <Box
      component="section"
      sx={{
        textAlign: "center",
        position: "relative",
        padding: "30px 0 0 0",
        minHeight: {
          xs: "initial",
          sm: "initial",
          md: "initial",
          xl: "initial",
        },
        padding: {
          xs: "30px 0 0 0",
          sm: "30px 0 0 0",
          md: "30px 0 0 0",
          lg: "30px 0 0 0",
        },
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          position: "relative",
          marginTop: {
            xs: `24px`,
            sm: `24px`,
            md: `calc((100vh - ${headerHeight}px) / 10)`,
            xl: `calc((100vh - ${headerHeight}px) / 10)`,
          },
        }}
      >
        <Typography
          variant="h1"
          fontWeight={500}
          sx={{
            fontSize: headlineSizeConfig,
            "& > span": {
              fontSize: headlineSizeConfig,
            },
            color: "text.white",
            textAlign: "center",
            maxWidth: "800px",
            lineHeight: 1.2,
            width: {
              xs: "100%",
              sm: `100%`,
              md: `100%`,
              xl: `100%`,
              lg: "100%",
            },
            margin: "auto",
          }}
        >
          The Best Image Generator For Blog Posts
        </Typography>
        <ResponsiveText
          color="text.white"
          variant="body2"
          fontSize={22}
          sx={{
            textAlign: "center",
            margin: "48px auto auto auto",
            maxWidth: "600px",
            fontWeight: 400,
          }}
        >
          <InlineSpan inherit>Create </InlineSpan>
          <InlineSpan
            inherit
            component="strong"
            sx={{
              fontWeight: 700,
            }}
          >
            realistic images
          </InlineSpan>
          <InlineSpan inherit>
            {" "}
            that aligns with the theme of your content in seconds.{" "}
          </InlineSpan>
        </ResponsiveText>
        {/*
          <Box
          sx={{
            marginTop: "24px",
            display: "flex",
            alignItems: "flex-end",
            columnGap: "8px",
          }}
        >
          <WriterList />
          <ResponsiveText
            fontSize={14}
            sx={{
              textAlign: "left",
              color: "text.white",
            }}
          >
            {t("14,637+ blog posts written.")}
          </ResponsiveText>
        </Box>
        */}

        <PrimaryCallToAction
          sx={{
            margin: "48px auto 48px auto",
            padding: "12px 12px",
            minWidth: "initial",
            maxWidth: "250px",
          }}
          to={dashboardRouteConfig.dashboard}
        >
          {t("Try For Free")}
        </PrimaryCallToAction>
      </Container>
    </Box>
  );
});

const MainHero = React.memo(() => {
  return <Hero />;
});

const ScriptRenderer = React.memo(() => {
  const { t } = useTranslation("front-page");
  const faqLists = useFaqList();
  return null;
  return (
    <>
      <ReviewMarkup />
      <Script
        type="application/ld+json"
        strategy="beforeInteractive"
        id="junia-ai-faq"
        key="junia-ai-faq"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(buildJsonLd(faqLists)),
        }}
      />
    </>
  );
});

const Struggle = React.memo(() => {
  const { t } = useTranslation("front-page");
  return (
    <Box
      component="section"
      sx={{
        padding: {
          xs: "70px 0",
          sm: "70px 0",
          md: "70px 0",
          lg: "124px 0",
        },
      }}
    >
      <Container maxWidth="xl">
        <ResponsiveText
          color="text.white"
          variant="h2"
          fontSize={55}
          sx={{
            textAlign: "center",
            maxWidth: "800px",
            margin: "auto",
          }}
          decrement="large"
        >
          Struggling to Get Traffic to Your Blog?
        </ResponsiveText>
        <ResponsiveText
          fontSize={20}
          sx={{
            maxWidth: "700px",
            margin: " 12px auto 0 auto",
            textAlign: "center",
          }}
          decrement="medium"
          color="#BCBCBC"
        >
          You work hard on your blog, but search engines aren't showing it.
          You're not alone - we've been there too.
        </ResponsiveText>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "72px",
          }}
        >
          <Box
            component="ul"
            sx={{
              padding: 0,
              margin: 0,
              display: "flex",
              flexDirection: "column",
              rowGap: "36px",
            }}
          >
            <ThirdSectionSection
              jsxIcon={
                <CheckOutlinedIcon
                  sx={{
                    fill: "white",
                  }}
                />
              }
              titleProps={{
                sx: {
                  fontWeight: 500,
                },
              }}
              title={t("You invest time in your blog.")}
              description={`You spend hours generating many blog posts.`}
            />
            <ThirdSectionSection
              jsxIcon={
                <CheckOutlinedIcon
                  sx={{
                    fill: "white",
                  }}
                />
              }
              titleProps={{
                sx: {
                  fontWeight: 500,
                },
              }}
              title={t("You hope to increase traffic on your site.")}
              description="With the aim to spend more time towards business growth instead."
            />
            <ThirdSectionSection
              noIconBg
              jsxIcon={
                <CloseOutlinedIcon
                  sx={{
                    fill: "#FF0000",
                    height: "50px",
                    width: "50px",
                  }}
                />
              }
              titleProps={{
                sx: {
                  fontWeight: 800,
                },
              }}
              title={t("You get 0 traffic to your blog.")}
              isLast
              description="Despite your efforts, search engines just don't notice. Money and time wasted."
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
});

export default function IndexPage() {
  return (
    <>
      <IndexPageHeadTags />
      <ScriptRenderer />

      <Box
        sx={{
          background:
            "linear-gradient(177.9deg, #111827 0%, #360F67 50%, #360F67 100%)",
        }}
      >
        <MainHero />

        <ThirdSection />
        <Features />
      </Box>
      <Box
        sx={{
          background:
            "linear-gradient(177.9deg, #111827 0%, #360F67 50%, #360F67 100%)",
        }}
      >
        <JuniaArtSection />
      </Box>
      {/*
        <JuniaChatSection />
      <Box
        sx={{
          background:
            "linear-gradient(177.9deg, #111827 0%, #360F67 50%, #360F67 100%)",
        }}
      >
        <JuniaArtSection />
        <SithSection />
      </Box>

    */}

      {/*
        <ContentCreation />
      <AiArt />
      <JuniaChat />
      <ImageRestorer />
      <AboutJunia />
      <PricingSection />
      <Faq />
  */}
    </>
  );
}

export async function getStaticProps() {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["front-page", "common"])),
      // Will be passed to the page component as props
    },
  };
}
