import { useTranslation } from "components/translate";
import React from "react";
import FaqList from "modules/non-protected-page/faq-list";

export const useFaqList = () => {
  const { t } = useTranslation("front-page");
  const faqLists = [
    {
      question: t("What is Junia.ai?"),
      answer: t(
        "Junia AI is an AI-powered content creation platform that helps users create high-quality, original content for blogs, emails, and ads in seconds. It also offers AI-generated images, SEO research, and a smart AI editor with various features like summarization, paraphrasing, translation, citations, and more."
      ),
    },
    {
      question: t("How does Junia differ from other AI tools?"),
      answer: t("junia-dffer-from-other-ai-tools-answer"),
    },
    {
      question: t("How does Junia AI use my data?"),
      answer: t(
        `Junia AI is committed to safeguarding your data and ensuring your privacy. We neither sell your data nor use it for training purposes. To learn more about Junia.ai's data security measures, visit <a href='/security' target='_blank'>our security policy page</a>. By prioritizing data protection, Junia.ai provides a secure environment where users can confidently create and manage their content without compromising their sensitive information. This commitment to security allows users to trust the platform and focus on producing high-quality content with peace of mind.`
      ),
    },
  ];

  return faqLists;
};
const Faq = React.memo(() => {
  const faqLists = useFaqList();
  return <FaqList faqLists={faqLists} />;
});

export default Faq;
