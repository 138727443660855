import React from "react";
import { Box } from "components/ui/box";

import {
  Container,
  ResponsiveText,
} from "modules/marketing-pages/front-page/shared";

import { Grid } from "components/ui/grid";
import Image from "next/image";

import { Button } from "components/ui/button";

import image1705 from "public/images/ai-art/1705.png";
import image1706 from "public/images/ai-art/1706.png";
import image1707 from "public/images/ai-art/1707.png";
import { NextLink } from "components/next-link";
import { serverRoutes } from "client-server-shared/config/routes";
import { InlineSpan } from "components/as-inline-span";
import { useTranslation } from "components/translate";

const JuniaArtSectionSection = ({ title, content }) => {
  return (
    <Box component="li">
      <Box
        component="article"
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "6px",
        }}
      >
        <ResponsiveText
          textAlign={"left"}
          fontWeight={600}
          variant="body1"
          color="text.white"
          sx={{
            maxWidth: "300px",
          }}
          fontSize={22}
        >
          {title}
        </ResponsiveText>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "0px",
          }}
        >
          {content.map((text, index) => {
            return (
              <ResponsiveText
                variant="body2"
                fontSize={16}
                sx={{
                  maxWidth: "500px",
                  color: "#CBCBCB",
                  textAlign: "left",
                }}
                key={index}
              >
                {text}
              </ResponsiveText>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

const JuniaArtSection = () => {
  const { t } = useTranslation("front-page");
  return (
    <Box
      component="section"
      sx={{
        padding: "124px 0 72px 0",
      }}
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "72px",
          }}
        >
          <Box>
            <ResponsiveText
              sx={{
                maxWidth: "800px",
              }}
              fontSize={45}
              decrement="medium"
              color="text.white"
              variant="h3"
            >
              {t("Enter a Realm of Imagination with BlogImagesAI")}
            </ResponsiveText>
            <ResponsiveText fontSize={20} color="#DDDDDD">
              Highly realistic visuals that breathe life into your blog content
            </ResponsiveText>
          </Box>
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "flex",
              },
              alignItems: "center",
            }}
          >
            <Image
              src={image1707}
              height={200}
              width={300}
              alt={t(
                `AI generated of a large waterfall is in the middle of a lake.`
              )}
              style={{
                width: "100%",
                height: "350px",
                marginLeft: "-20px",
                objectFit: "cover",
              }}
            />
            <Image
              src={image1705}
              height={400}
              width={500}
              alt={t(
                `AI generated of a large dinosaur standing in front of a city.`
              )}
              style={{
                zIndex: 1,
                width: "100%",
                objectFit: "cover",

                height: "450px",
              }}
            />
            <Image
              src={image1706}
              height={200}
              width={300}
              alt={t(
                "AI generated of a painting of an elephant walking across a field."
              )}
              style={{
                width: "100%",
                height: "350px",
                marginRight: "-20px",
                objectFit: "cover",
              }}
            />
          </Box>
          <Box
            sx={{
              display: {
                xs: "flex",
                sm: "flex",
                md: "flex",
                lg: "none",
              },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component={Image}
              src={image1705}
              height={400}
              width={300}
              alt={t(
                `AI generated of a large dinosaur standing in front of a city.`
              )}
              sx={{
                zIndex: 1,
                objectFit: "cover",
                height: {
                  xs: "300px",
                  sm: "300px",
                  md: "400px",
                },

                width: {
                  xs: "200px",
                  sm: "200px",
                  md: "300px",
                },
              }}
            />
            <Box>
              <Box
                src={image1707}
                component={Image}
                height={200}
                width={300}
                alt={t(
                  `AI generated of a large waterfall is in the middle of a lake.`
                )}
                sx={{
                  width: {
                    xs: "200px",
                    sm: "200px",
                    md: "300px",
                  },
                  height: {
                    xs: "150px",
                    sm: "150px",
                    md: "200px",
                  },
                  objectFit: "cover",
                }}
              />

              <Box
                src={image1706}
                component={Image}
                height={200}
                width={300}
                alt={t(
                  "AI generated of a painting of an elephant walking across a field."
                )}
                sx={{
                  width: {
                    xs: "200px",
                    sm: "200px",
                    md: "300px",
                  },
                  height: {
                    xs: "150px",
                    sm: "150px",
                    md: "200px",
                  },

                  objectFit: "cover",
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingBottom: "72px",
            }}
          >
            <ResponsiveText
              sx={{
                maxWidth: "700px",
                textAlign: "center",
              }}
              color="text.white"
              fontSize={40}
              decrement="medium"
              variant="h4"
            >
              {t("Lifelike Masterpieces that Transport You to Another World")}
            </ResponsiveText>
            <Box>
              <Button
                capitalize
                component={NextLink}
                href={serverRoutes.register}
                variant="contained"
                sx={{
                  marginTop: "24px",
                  borderRadius: "22px",
                  padding: "12px 36px",
                  background:
                    "linear-gradient(90deg, #CF24BE -24.5%, #360F67 100%)",
                  fontSize: "16px",
                  "&:hover": {
                    background:
                      "linear-gradient(90deg, #CF24BE -24.5%, #360F67 100%)",
                    opacity: 0.8,
                  },
                }}
              >
                {t("Try Blog AI Images for free now")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default JuniaArtSection;
