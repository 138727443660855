import { AutoPlayVideo, Video } from "components/video/auto-play-video";
import React from "react";
import { Container, ResponsiveText } from "./shared";
import { Box } from "components/ui/box";
import Image from "next/image";
import { Grid } from "components/ui/grid";
import { IconComponent } from "components/icon-component";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import GTranslateOutlinedIcon from "@mui/icons-material/GTranslateOutlined";
import { FeatureItem } from "modules/non-protected-page/feature-list";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import SEOMetadata from "public/images/generator/seo-metadata.png";
import { Button } from "components/ui/button";
import { NextLink } from "components/next-link";
import { serverRoutes } from "client-server-shared/config/routes";
import { Trans, useTranslation } from "components/translate";
import FormatListNumberedOutlinedIcon from "@mui/icons-material/FormatListNumberedOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import QuickreplyOutlinedIcon from "@mui/icons-material/QuickreplyOutlined";
import { InlineSpan } from "components/as-inline-span";
import { Avatar } from "components/ui/avatar";
import FormatQuoteOutlinedIcon from "@mui/icons-material/FormatQuoteOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import DraftsIcon from "@mui/icons-material/Drafts";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
const ContentRenderer = ({
  headline,
  subheadline,
  videoUrl,
  imageUrl,
  imageAlt,
  disableAutoPlay,
  rootProps = {},
}: any & { isSelected: boolean }) => {
  return (
    <Box
      component="article"
      sx={{
        backgroundColor: "#111827",
        height: "100%",
        borderRadius: "22px",
        boxShadow:
          "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        ...rootProps,
      }}
    >
      <Box
        sx={{
          padding: {
            sm: "16px",
            xs: "16px",
            md: "24px",
            lg: "48px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "48px",
            rowGap: "24px",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexBasis: "100%",
              width: "100%",
              position: "relative",
            }}
          >
            <ResponsiveText
              variant="h3"
              fontSize={30}
              decrement="medium"
              sx={{
                marginBottom: "12px",
                color: "text.white",
                fontWeight: 600,
              }}
            >
              {headline}
            </ResponsiveText>
            {subheadline && (
              <ResponsiveText
                fontSize={16}
                sx={{
                  color: "#D4DCE5",
                  marginBottom: "24px",
                  maxWidth: "500px",
                }}
              >
                {subheadline}
              </ResponsiveText>
            )}
          </Box>
          {videoUrl && (
            <Box
              sx={{
                position: "relative",
                display: "flex",
                height: {
                  sm: "initial",
                  xs: "initial",
                  md: "initial",
                  lg: "100%",
                  xl: "100%",
                },
                flexShrink: 0,
                "& img": {
                  height: {
                    sm: "100%",
                    xs: "100%",
                    md: "initial",
                    lg: "initial",
                    xl: "initial",
                  },
                },
              }}
            >
              {disableAutoPlay ? (
                <Video
                  videoUrl={videoUrl}
                  controls
                  imageUrl={imageUrl}
                  style={{
                    borderRadius: "22px",
                  }}
                />
              ) : (
                <AutoPlayVideo
                  videoUrl={videoUrl}
                  controls
                  delay={500}
                  imageUrl={imageUrl}
                  style={{
                    borderRadius: "22px",
                  }}
                />
              )}
            </Box>
          )}
          {imageUrl && !videoUrl ? (
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexBasis: {
                  sm: "initial",
                  xs: "initial",
                  md: "initial",
                  lg: "50%",
                  xl: "50%",
                },
                height: {
                  sm: "initial",
                  xs: "initial",
                  md: "initial",
                  lg: "100%",
                  xl: "100%",
                },
                flexShrink: 0,
                "& img": {
                  height: {
                    sm: "100%",
                    xs: "100%",
                    md: "initial",
                    lg: "initial",
                    xl: "initial",
                  },
                },
              }}
            >
              <Image
                src={imageUrl}
                alt={imageAlt}
                height={600}
                width={600}
                style={{
                  width: "100%",
                  borderRadius: "22px",
                }}
              />
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

const SmallCard = ({
  title,
  description,
  imageUrl,
  icon,
  iconProps = {},
  alt,
}) => {
  return (
    <Box
      component="article"
      sx={{
        backgroundColor: "#111827",
        height: "100%",
        borderRadius: "10px",
        boxShadow:
          "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
      }}
    >
      <Box
        sx={{
          padding: "24px 24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "48px",
            rowGap: "24px",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexBasis: "100%",
              width: "100%",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "12px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "10px",
                    padding: "6px",
                    backgroundColor: "rgba(41, 52, 76, 1)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <IconComponent
                    icon={icon}
                    {...iconProps}
                    sx={{
                      height: "24px",
                      width: "24px",
                      fill: "#D4DCE5",
                      ...(iconProps.sx || {}),
                    }}
                  />
                </Box>
              </Box>

              <ResponsiveText
                variant="h6"
                fontSize={18}
                sx={{
                  marginBottom: "6px",
                  color: "text.white",
                  fontWeight: 600,
                }}
              >
                {title}
              </ResponsiveText>
            </Box>
            {description && (
              <ResponsiveText
                fontSize={14}
                sx={{
                  color: "#D4DCE5",
                  maxWidth: "500px",
                }}
              >
                {description}
              </ResponsiveText>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

/*
        <Grid
            container
            spacing={3}
            sx={{
              margin: "12px 0 24px 0",
              ...heroSpacing,
            }}
          >
            <Grid sm={12} md={6} lg={4}>
              <SmallCard
                icon={MoreHorizOutlinedIcon}
                title={t("Continue writing")}
                description={t(
                  "Junia AI can seemlingly continue from where you left off, ensuring you one coherent article"
                )}
              />
            </Grid>
            <Grid sm={12} md={6} lg={4}>
              <SmallCard
                icon={EditOutlinedIcon}
                title={t("Paraphrase")}
                description={t(
                  "Rewrite your text in a fresh and engaging manner, without losing the essence of the original message."
                )}
              />
            </Grid>
            <Grid sm={12} md={6} lg={4}>
              <SmallCard
                icon={GTranslateOutlinedIcon}
                title={t("Rewrite to any language")}
                description={t(
                  "Repurpose your content for a global audience utilizing Junia's expertise in over 30+ languages."
                )}
              />
            </Grid>
          </Grid>
*/

export const Features = () => {
  const { t } = useTranslation("front-page");
  return (
    <>
      <Box
        component="section"
        sx={{
          padding: {
            xs: "70px 0 70px 0",
            sm: "70px 0 70px 0",
            md: "70px 0 70px 0",
            lg: "70px 0 70px 0",
          },
        }}
      >
        <Container>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexBasis: "100%",
              width: "100%",
              position: "relative",
              margin: "0 0 70px 0",
            }}
          >
            <ResponsiveText
              variant="h2"
              fontSize={50}
              decrement="medium"
              sx={{
                maxWidth: "800px",
                color: "text.white",
                textAlign: "center",
                margin: "auto",
                fontWeight: 700,
              }}
            >
              {t("The AI Image Tool Your Blog Needs")}
            </ResponsiveText>
            <ResponsiveText
              fontSize={18}
              sx={{
                color: "#D4DCE5",
                maxWidth: "500px",
                textAlign: "center",
                margin: "12px auto auto auto",
              }}
            >
              {t(
                "Discover how BlogImagesAI enhances your content visuals and makes your blog posts stand out."
              )}
            </ResponsiveText>
          </Box>
          <Grid
            component="ul"
            container
            spacing={3}
            sx={{
              padding: 0,
              listStyle: "none",
              margin: "12px 0 24px 0",
            }}
          >
            <Grid component="li" xs={12} sm={12} md={6} lg={4}>
              <SmallCard
                icon={BarChartOutlinedIcon}
                iconProps={{
                  sx: {
                    fill: "rgb(216 76 167)",
                  },
                }}
                title={t("Outshine Your Competitors")}
                description={t(
                  "Get superior blog visuals based on the context of your content."
                )}
              />
            </Grid>
            <Grid component="li" xs={12} sm={12} md={6} lg={4}>
              <SmallCard
                icon={DraftsIcon}
                iconProps={{
                  sx: {
                    fill: "#f1e713",
                  },
                }}
                title={t("True Visual Storytelling")}
                description={t(
                  "Transform your blog posts into visually engaging stories with AI-selected images, optimized for both appeal and relevance."
                )}
              />
            </Grid>
            <Grid component="li" xs={12} sm={12} md={6} lg={4}>
              <SmallCard
                iconProps={{
                  sx: {
                    fill: "#dc9ad9",
                  },
                }}
                icon={RocketLaunchOutlinedIcon}
                title={t("Boost Blog Engagement")}
                description={t(
                  "Gradually gain trust in the eyes of your readers with visually rich blog posts."
                )}
              />
            </Grid>
            <Grid component="li" xs={12} sm={12} md={6} lg={4}>
              <SmallCard
                icon={FormatListNumberedOutlinedIcon}
                iconProps={{
                  sx: {
                    fill: "#f1e713",
                  },
                }}
                title={t("Richly Illustrated Content")}
                description={t(
                  "Produce well-structured articles illustrated with relevant images that enhance the reading experience."
                )}
              />
            </Grid>
            <Grid component="li" xs={12} sm={12} md={6} lg={4}>
              <SmallCard
                icon={AccountTreeOutlinedIcon}
                title={t("Attract More Shares")}
                iconProps={{
                  sx: {
                    fill: "#00e983",
                  },
                }}
                description={t(
                  "Make your blog post irresistible with stunning images that are sure to get more social shares and audience engagement."
                )}
              />
            </Grid>

            <Grid component="li" xs={12} sm={12} md={6} lg={4}>
              <SmallCard
                icon={HistoryEduIcon}
                title={t("Effortless Image Refresh")}
                iconProps={{
                  sx: {
                    fill: "#04d691",
                  },
                }}
                description={t(
                  "Revitalize your blog posts with AI-driven image updates, boosting visual appeal and reader engagement."
                )}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              capitalize
              variant="contained"
              component={NextLink}
              href={serverRoutes.register}
              sx={{
                marginTop: "12px",
                borderRadius: "22px",
                padding: "12px 36px",
                background: "#CF24BE",
                fontSize: "20px",
                "&:hover": {
                  background: "#CF24BE",
                  opacity: 0.8,
                },
              }}
            >
              <ResponsiveText fontSize={20} decrement="medium">
                {t("Try For Free")}
              </ResponsiveText>
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};
